import styled from 'styled-components';
import { FullSizeContainer } from '@/shared/ui/styled';

export const LinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  grid-gap: 15px;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
      flex: none;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    padding: 25px 0;
    flex-direction: column;
    grid-gap: 32px;

    &:first-child {
      margin-bottom: 0;
    }
  }
`;

export const SocialNetworksWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  grid-gap: 15px;
`;

export const SocialNetworksButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  margin: 0;
  padding: 0;
  width: 48px;
  height: 48px;
  border-radius: 100vmax;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => props.theme.primary.searchInputBorderColor};

  a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    width: 32px;
    height: 32px;
    fill: ${(props) => props.theme.primary.text};
  }
`;

export const ListItem = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #7f7f7f;
  cursor: pointer;

  > a {
    color: inherit;
    text-decoration: none;
  }

  span {
    cursor: pointer;
    color: ${(props) => props.theme.common.main};
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
`;

export const ListTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.primary.text};
`;

export const Chat = styled(ListItem)`
  display: flex;
  align-items: center;
  grid-gap: 10px;
  margin: 0;
  padding: 0;
  color: rgba(255, 255, 255, 0.85) !important;

  > div {
    border-radius: 3px;
    width: 22px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      width: 24px;
      height: 24px;
    }
  }
`;

export const ListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 35px;

   @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    align-items: center;
    grid-gap: 16px;

    ${ListItem} {
      text-align: center;
    }
  }
`;

export const BottomSection = styled.div`
  padding-bottom: 70px;

  ${FullSizeContainer} {
    padding-top: 26px;
    padding-bottom: 26px;
    border-top: 1px solid ${(props) => props.theme.primary.borderColor};
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #7f7f7f;

    > div:last-child {
      width: min-content;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      grid-gap: 5px;
    }

    ul {
      justify-content: center;
      flex: 1;
      list-style: none;
      display: flex;
      margin: 0;
      padding: 0;
      grid-gap: 50px;

      li {
        a {
          text-decoration: none;
          color: inherit;
        }
      }

      @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        grid-gap: 20px;
      } 
    } 

    @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
      align-items: center;
      flex-direction: column;
      gap: 10px;
    }
  }
`;

export const TopSection = styled.div`
  border-top: 1px solid ${(props) => props.theme.primary.borderColor};
  border-left: 0;
  border-right: 0;
  padding: 50px 0;

  ${FullSizeContainer} {
    display: flex;
    gap: 150px;

    > div {
      &:first-child {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      &:last-child {
        width: 100%;
      }

      > ul {
        display: none;
        flex-direction: column;
        margin: 0 0 25px 0;
        padding: 0;
        grid-gap: 16px;
        color: #7f7f7f;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;

        li {
          a {
            text-decoration: none;
            color: inherit;
          }
        }
      }

      @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
        > ul {
          display: flex;
        }
      }
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.lg}px) {
      gap: 100px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    padding: 0;

    ${FullSizeContainer} {
      flex-direction: column;
      gap: 0;

      > div:first-child {
        align-items: center;
        padding: 25px 0;
        border-right: none;
        border-bottom: 1px solid ${(props) => props.theme.primary.borderColor};
      }
    }
  }
`;

export const Wrapper = styled.footer`
  z-index: 1;
`;
