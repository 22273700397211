'use client';

import React from 'react';
import Image from 'next/image';
import { useAnalyticsContext } from '@/context/AnalyticsContext';
import { usePageContext } from '@/context/PageContextProvider';
import ThemeSwitcher from '@/features/ThemeSwitcher';
import Logo from '@/entities/Logo';
import { ESIMPLUS_LINK, ONE_LINK } from '@/shared/constants';
import { useModalControls } from '@/shared/hooks';
import Icon from '@/shared/ui/Icon';
import Button from '@/shared/ui/Button';
import { XMarkIcon } from '@/shared/ui/Icon/svg';
import burgerMenu from './assets/burger-menu.png';
import * as SC from './styled';

function Navbar() {
  const { source } = usePageContext();
  const { sendSafeMixpanelEvent } = useAnalyticsContext();

  const {
    isOpen: isNavMenuOpen,
    closeModal: closeNavMenu,
    openModal: openNavMenu,
  } = useModalControls(false, { disableBodyScroll: true });

  const handleSignInClick = () => {
    sendSafeMixpanelEvent('track', 'onelink-click', { source })
  };

  return (
    <>
      <div style={{ paddingBottom: 65 }} />
      <SC.Wrapper id="navbar">
        <SC.Container style={{ color: 'inherit' }}>
          <SC.SideWrapper $align="left">
            <Logo />
          </SC.SideWrapper>
          <SC.LinksWrapper>
            <SC.NavLink href={ESIMPLUS_LINK} $isActive>
              Mobile data
            </SC.NavLink>
            <SC.NavLink href={`${ESIMPLUS_LINK}/virtual-phone-number`}>
              Virtual Phone Number
            </SC.NavLink>
            <SC.DownloadAppLink target="_blank" rel="nofollow" href={ONE_LINK}>
              Download the App
            </SC.DownloadAppLink>
          </SC.LinksWrapper>
          <SC.SideWrapper $align="right">
            <SC.SettingsWrapper>
              <ThemeSwitcher />
              <SC.ButtonsWrapper>
                <Button
                  style={{ height: 48 }}
                  onClick={handleSignInClick}
                  label={
                    <a target="_blank" rel="noreferrer" href={ESIMPLUS_LINK}>
                      Get Started
                    </a>
                  }
                />
              </SC.ButtonsWrapper>
              <SC.BurgerMenu>
                <SC.BurgerMenuButton onClick={openNavMenu}>
                  <Image width={20} height={20} src={burgerMenu} alt="burger menu" />
                </SC.BurgerMenuButton>
                <SC.NavMenu $isOpen={isNavMenuOpen}>
                  <div>
                    <Logo />
                    <button type="button" onClick={closeNavMenu}>
                      <Icon width={14} height={14} component={XMarkIcon} />
                    </button>
                  </div>
                  <ul>
                    <SC.NavMenuItem>
                      <SC.NavLink href={ESIMPLUS_LINK} $isActive>
                        Mobile data
                      </SC.NavLink>
                    </SC.NavMenuItem>
                    <SC.NavMenuItem>
                      <SC.NavLink href={`${ESIMPLUS_LINK}/virtual-phone-number`}>
                        Virtual Phone Number
                      </SC.NavLink>
                    </SC.NavMenuItem>
                    <SC.NavMenuItem>
                      <SC.NavLink target="_blank" rel="nofollow" href={ONE_LINK}>
                        Download the App
                      </SC.NavLink>
                    </SC.NavMenuItem>
                  </ul>
                  <Button
                    style={{ height: 45 }}
                    label={
                      <a target="_blank" rel="noreferrer" href={ESIMPLUS_LINK}>
                        Get Started
                      </a>
                    }
                  />
                </SC.NavMenu>
              </SC.BurgerMenu>
            </SC.SettingsWrapper>
          </SC.SideWrapper>
        </SC.Container>
      </SC.Wrapper>
    </>
  );
}

export { Navbar };
