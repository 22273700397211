'use client';

import React from 'react';
import Logo from '@/entities/Logo';
import Icon from '@/shared/ui/Icon';
import { FullSizeContainer } from '@/shared/ui/styled';
import { ESIMPLUS_LINK } from '@/shared/constants';
import { FacebookIcon, TelegramIcon, LnIcon, WhatWeAcceptIcon } from '@/shared/ui/Icon/svg';
import {
  ListItem,
  List,
  ListTitle,
  Wrapper,
  ListWrapper,
  SocialNetworksWrapper,
  LinksWrapper,
  TopSection,
  BottomSection,
  SocialNetworksButton,
} from './styled';

function Footer() {

  return (
    <Wrapper>
      <TopSection>
        <FullSizeContainer>
          <div>
            <Logo />
            <SocialNetworksWrapper>
              <SocialNetworksButton type="button">
                <a target="_blank" rel="nofollow" href="https://www.facebook.com/esimplus.me/">
                  <Icon component={FacebookIcon} />
                </a>
              </SocialNetworksButton>
              <SocialNetworksButton type="button">
                <a target="_blank" rel="nofollow" href="https://www.linkedin.com/company/esimplus">
                  <Icon component={LnIcon} />
                </a>
              </SocialNetworksButton>
              <SocialNetworksButton type="button">
                <a target="_blank" rel="nofollow" href="https://t.me/esimplus_official">
                  <Icon component={TelegramIcon} />
                </a>
              </SocialNetworksButton>
            </SocialNetworksWrapper>
          </div>
          <div>
            <LinksWrapper>
              <div>
                <ListWrapper>
                  <ListTitle>Services</ListTitle>
                  <List>
                    <ListItem>
                      <a href={`${ESIMPLUS_LINK}/virtual-phone-number`}>Virtual Phone Number</a>
                    </ListItem>
                    <ListItem>
                      <a href={`${ESIMPLUS_LINK}/virtual-phone-number/pricing`}>Pricing</a>
                    </ListItem>{' '}
                    <ListItem>
                      <a href={`${ESIMPLUS_LINK}/random-phone-number-generator`}>Phone Number Generator</a>
                    </ListItem>
                  </List>
                </ListWrapper>
              </div>
              <div>
                <ListWrapper>
                  <ListTitle>Support</ListTitle>
                  <List>
                    <ListItem>
                      <a href={`${ESIMPLUS_LINK}/faq`}>Frequently Asked Questions</a>
                    </ListItem>{' '}
                    <ListItem>
                      <a href={`${ESIMPLUS_LINK}/esim-supported-devices`}>eSIM-compatible Devices</a>
                    </ListItem>
                    <ListItem>
                      <a href={`${ESIMPLUS_LINK}/contact-us`}>Contact Us</a>
                    </ListItem>{' '}
                  </List>
                </ListWrapper>
              </div>
              <div>
                <ListWrapper>
                  <ListTitle>About Us</ListTitle>
                  <List>
                    <ListItem>
                      <a href={`${ESIMPLUS_LINK}/about-us`}>About Us</a>
                    </ListItem>{' '}
                    <ListItem>
                      <a href={`${ESIMPLUS_LINK}/reviews`}>Reviews</a>
                    </ListItem>{' '}
                    <ListItem>
                      <a href={`${ESIMPLUS_LINK}/news`}>News</a>
                    </ListItem>
                  </List>
                </ListWrapper>
              </div>
            </LinksWrapper>
          </div>
        </FullSizeContainer>
      </TopSection>
      <BottomSection>
        <FullSizeContainer>
          <div>Appvillis UAB, {new Date().getFullYear()}</div>
          <ul>
            <li>
              <a href={`${ESIMPLUS_LINK}/privacy`}>Privacy Policy</a>
            </li>
            <li>
              <a href={`${ESIMPLUS_LINK}/terms`}>Terms of use</a>
            </li>
            <li>
              <a href={`${ESIMPLUS_LINK}/refund-policy`}>Refund Policy</a>
            </li>
          </ul>
          <div>
            <Icon component={WhatWeAcceptIcon} width={130} height={24} />
          </div>
        </FullSizeContainer>
      </BottomSection>
    </Wrapper>
  );
}

export { Footer };
