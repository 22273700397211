'use client';

import styled from 'styled-components';

export const LinkButton = styled.button`
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  color: ${(props) => props.theme.common.main};
  font-weight: 300;
  font-size: inherit;
  cursor: pointer;
`;

export const Container = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 50px;
  max-width: 1240px;
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.primary.text};

   @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    padding: 0;
  }
`;

export const FullSizeContainer = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 50px;
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.primary.text};

  @media (max-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    padding: 0 20px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    padding: 0 16px;
  }
`;