import { Bundle, Country, EcommpayPurchaseProfileResponse } from '@/shared/types';
import { MAIN_API_URL } from '@/shared/constants';
import { delay, getCookie } from '@/shared/utils';
import { queryFetcher } from './index';

const ENDPOINTS = {
  countriesByFilterText: (filterText: string, locale: string) => `/v6/countries/search/${filterText}/${locale}`,
  discountBundle: () => "/v6/bundles/discount",
  ecommpayPurchaseProfile: () =>
    "/v6/payment-providers/ecommpay/purchase/profile",
  purchaseProfileWithCrypto: () =>
    "/v6/payment-providers/thedex/purchase/profile",
  webpayPurchase: () => `/v6/payment-providers/webpay/purchase/profile`,
  checkPaymentStatus: () => '/v6/payment-providers/is-payment-paid',
  createTempUser: () => `/v6/auth/temp-user`,
};

function getCountriesByFilterText(text: string, locale: string) {
  return queryFetcher<{ data: Country[] }>(MAIN_API_URL.concat(ENDPOINTS.countriesByFilterText(text, locale)));
}

function getDiscountBundle() {
  return queryFetcher<{ data: { discountBundle: Bundle } }>(
    MAIN_API_URL.concat(ENDPOINTS.discountBundle()), {
    headers: {
      'x-advertisement-code': process.env.NEXT_PUBLIC_ADVERTISEMENT_CODE ?? '',
    },
  });
}

function purchaseProfileWithCrypto(props: {
  paymentCode: string;
  successUrl?: string;
  failureUrl?: string;
}) {
  return queryFetcher<{ data: { payUrl: string } }>(
    MAIN_API_URL.concat(ENDPOINTS.purchaseProfileWithCrypto()),
    {
      method: "POST",
      body: JSON.stringify(props),
      headers: {
        'x-system-auth-token': getCookie('tmp_usr_session') ?? '',
      },
    }
  );
}

function purchaseProfile(props: {
  paymentCode: string;
  languageCode: string;
  redirectSuccessUrl?: string;
}) {
  return queryFetcher<{ data: EcommpayPurchaseProfileResponse }>(
    MAIN_API_URL.concat(ENDPOINTS.ecommpayPurchaseProfile()),
    {
      method: "POST",
      body: JSON.stringify(props),
      headers: {
        'x-system-auth-token': getCookie('tmp_usr_session') ?? '',
      },
    }
  );
}

function purchaseProfileWithWebpay(payload: {
  paymentCode: string;
  successUrl?: string;
  failureUrl?: string;
}) {
  return queryFetcher<{
    data: {
      payUrl: string;
      paymentId: string;
    }
  }>(MAIN_API_URL.concat(ENDPOINTS.webpayPurchase()), {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      'x-system-auth-token': getCookie('tmp_usr_session') ?? '',
    },
  });
}

function createTempUser() {
  return queryFetcher<{ data: { systemAuthToken: string } }>(`${MAIN_API_URL}${ENDPOINTS.createTempUser()}`, {
    method: 'POST',
    credentials: 'include',
  });
}

function checkPaymentStatus(paymentId: string) {
  return queryFetcher<{ data: { paid: boolean } }>(`${MAIN_API_URL}${ENDPOINTS.checkPaymentStatus()}`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({ paymentId }),
    headers: {
      'x-system-auth-token': getCookie('tmp_usr_session') ?? '',
    },
  });
}

const recursiveCheckPaymentStatus = async ({
  paymentId,
  attempts,
  delayMS,
}: {
  paymentId: string;
  attempts: number;
  delayMS: number;
}): Promise<{ error?: string | null; status?: 'OK' }> => {
  if (attempts === 0) {
    return { error: 'No attempts left.' };
  }

  const { data, error } = await checkPaymentStatus(paymentId);

  if (data?.data?.paid) {
    return { status: 'OK' };
  }

  if (error) {
    return { error };
  }

  await delay(delayMS);

  return recursiveCheckPaymentStatus({ paymentId, attempts: attempts - 1, delayMS });
};

export {
  ENDPOINTS,
  getDiscountBundle,
  createTempUser,
  recursiveCheckPaymentStatus,
  getCountriesByFilterText,
  purchaseProfileWithCrypto,
  purchaseProfile,
  purchaseProfileWithWebpay,
};
